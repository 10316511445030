import { HttpLink, InMemoryCache, split, ApolloClient } from 'apollo-boost';
import { WebSocketLink } from 'apollo-link-ws';
import { getMainDefinition } from 'apollo-utilities';

const httpURI = `${process.env.REACT_APP_GRAPHQL_ENDPOINT}`;
const wsURI = `${process.env.REACT_APP_WEBSOCKET_ENDPOINT}`;

const httpLink = new HttpLink({
  uri: httpURI,
});

const wsLink = new WebSocketLink({
  uri: wsURI,
  options: { reconnect: true },
});

const cache = new InMemoryCache();

// using the ability to split links, you can send data to each link
// depending on what kind of operation is being sent
const link = split(
  // split based on operation type
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
  },
  wsLink,
  httpLink,
);

export default new ApolloClient({
  link: link,
  cache,
});
