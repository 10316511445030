import React, { useCallback, useEffect, useState } from 'react';
import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import { useAuth0 } from './auth-context';

const GET_USER_FROM_DB = gql`
  query userFromDb($auth0Id: String!) {
    user(where: { auth0Id: { _eq: $auth0Id } }) {
      id
      auth0Id
    }
  }
`;

const UserContext = React.createContext();

const UserContextProvider = ({ children }) => {
  const { user } = useAuth0();
  const [fetchUserData, { data }] = useLazyQuery(GET_USER_FROM_DB);
  const [userData, setUserData] = useState();

  useEffect(() => {
    if (user && user.sub) {
      fetchUserData({ variables: { auth0Id: user.sub } });
    }
  }, [fetchUserData, user]);

  useEffect(() => {
    setUserData(data);
  }, [data]);

  return <UserContext.Provider value={{ userData, setUserData }}>{children}</UserContext.Provider>;
};

export { UserContext, UserContextProvider };
