import React from 'react';
import { Auth0Provider, useAuth0 } from './auth-context';
import { UserContextProvider } from './user.context';

const onRedirectCallback = appState => {
  window.history.replaceState(
    {},
    document.title,
    appState && appState.targetUrl ? appState.targetUrl : window.location.pathname,
  );
};

const AppProvider = ({ children }) => {
  return (
    <Auth0Provider
      domain={`${process.env.REACT_APP_AUTH0_DOMAIN}`}
      client_id={`${process.env.REACT_APP_AUTH0_CLIENT_ID}`}
      redirect_uri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
    >
      <UserContextProvider>{children}</UserContextProvider>
    </Auth0Provider>
  );
};

export default AppProvider;
