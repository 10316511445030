import React, { Suspense, useEffect, useState } from 'react';
import { useAuth0 } from './context/auth-context';
import { CircularProgress } from '@material-ui/core';

const loadAuthenticatedApp = () => import('./authenticated-app');
const AuthenticatedApp = React.lazy(loadAuthenticatedApp);
const UnauthenticatedApp = React.lazy(() => import('./unauthenticated-app'));

const App = () => {
  const { isAuthenticated } = useAuth0();

  useEffect(() => {
    loadAuthenticatedApp();
  }, []);

  return (
    <Suspense fallback={<CircularProgress />}>
      {isAuthenticated ? <AuthenticatedApp /> : <UnauthenticatedApp />}
    </Suspense>
  );
};

export default App;
