import React from 'react';
import { render } from 'react-dom';
import { ApolloProvider } from '@apollo/react-hooks';
import ApolloClient from './utils/ApolloClient';
import App from './App';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import AppProvider from './context/app-provider';
import * as serviceWorker from './serviceWorker';
import './App.css';

const history = createBrowserHistory();

render(
  <ApolloProvider client={ApolloClient}>
    <Router history={history}>
      <AppProvider>
        <App />
      </AppProvider>
    </Router>
  </ApolloProvider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
